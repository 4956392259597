<script>
export default {
  props: {
    item: {
      required: true
    },
    index: {
      required: true,
      type: Number
    }
  },
  
  render() {
    return this.$scopedSlots.default({
      select: this.select,
      selected: this.item.selected
    })
  },

  methods: {
    select() {
      if(this.item.selected) return
      this.$emit('click:select', this.index)
    }
  }

}
</script>